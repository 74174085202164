<template>
    <b-card v-if="tableData" no-body class="card-company-table">
        <b-table :items="tableData" responsive :fields="fields" class="mb-0">
            <!-- company -->
            <template #cell(person)="data">
                <div class="d-flex align-items-center">
                    <b-avatar rounded size="30" variant="light-company" :src="data.item.avatar">
                        <!-- :text="data.item.fullName" -->
                        <feather-icon v-if="!data.item.avatar" icon="UserIcon" size="22" />
                    </b-avatar>
                    <div class="ml-1">
                        <div class="font-weight-bolder">
                            {{ data.item.fullName }}
                        </div>
                        <div class="font-small-2 text-muted">
                            {{ data.item.email }}
                        </div>
                    </div>
                </div>
            </template>

            <!-- category -->
            <template #cell(department)="data">
                <div class="d-flex align-items-center">
                    <b-avatar
                        class="mr-1"
                        :variant="`${resolveUserDepartmentIconVariant(data.item.department).variant}`"
                    >
                        <feather-icon
                            size="18"
                            :icon="`${resolveUserDepartmentIconVariant(data.item.department).icon}`"
                        />
                    </b-avatar>
                    <span>{{ data.item.department }}</span>
                </div>
            </template>

            <!-- views -->
            <template #cell(phone)="data">
                <div class="d-flex flex-column">
                    <span class="font-weight-bolder mb-25 text-nowrap">{{ data.item.phone }}</span>
                    <!-- <span class="font-small-2 text-muted text-nowrap">{{ data.item.viewsub }}</span> -->
                </div>
            </template>

            <!-- status -->
            <template #cell(status)="data">
                <div class="d-flex flex-column">
                    <b-badge v-if="data.item.isActive" variant="success" class="badge-glow"> Active </b-badge>
                    <b-badge v-else variant="danger" class="badge-glow"> Deactive </b-badge>
                    <!-- <span class="font-weight-bolder mb-25">{{ resolveIsActive(data.item.isActive) }}</span> -->
                </div>
            </template>
            <!-- Last Activity -->
            <template #cell(lastActivity)="data">
                <div class="d-flex flex-column">
                    <span class="font-weight-bolder mb-25"> {{ data.item.updatedDate | formatDate }}</span>
                </div>
            </template>
        </b-table>
    </b-card>
</template>

<script>
import { BAvatar, BBadge, BCard, BTable } from "bootstrap-vue";

// const resolveIsActive = activity => activity ? "Active" : "Inactive";

const resolveUserDepartmentIconVariant = department => {
    if (department === "Technology") {
        return {
            icon: "DatabaseIcon",
            variant: "light-primary",
        };
    }

    if (department === "Marketing") {
        return {
            icon: "MailIcon",
            variant: "light-success",
        };
    }

    if (department === "Sales") {
        return {
            icon: "ShoppingCartIcon",
            variant: "light-warning",
        };
    }

    if (department === "Product") {
        return {
            icon: "WatchIcon",
            variant: "light-danger",
        };
    }

    return {
        icon: "WatchIcon",
        variant: "light-danger",
    };
};

export default {
    components: {
        BCard,
        BTable,
        BAvatar,
        BBadge,
    },
    props: {
        tableData: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            fields: [
                {
                    key: "person",
                    label: "PERSON",
                },
                {
                    key: "department",
                    label: "DEPARTMENT",
                },
                {
                    key: "phone",
                    label: "PHONE",
                },
                {
                    key: "status",
                    label: "STATUS",
                },
                {
                    key: "lastActivity",
                    label: "LAST ACTIVITY",
                },
            ],
        };
    },
    methods: {
        resolveUserDepartmentIconVariant,
        // resolveIsActive,
    },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
