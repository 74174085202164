<template>
    <b-card v-if="data" no-body class="card-revenue-budget">
        <b-row class="mx-0">
            <b-col md="12" class="revenue-report-wrapper">
                <div class="d-sm-flex justify-content-between align-items-center mb-3">
                    <h4 class="card-title mb-50 mb-sm-0">Revenue Report</h4>
                    <div class="d-flex align-items-center">
                        <div class="d-flex align-items-center mr-2">
                            <span class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer" />
                            <span>Revenue</span>
                        </div>
                        <div class="d-flex align-items-center mr-2">
                            <span class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer" />
                            <span>Quantity</span>
                        </div>
                    </div>
                </div>

                <!-- chart -->
                <vue-apex-charts
                    id="revenue-report-chart"
                    height="290"
                    :options="revenueReport.chartOptions"
                    :series="data.revenueReport.series"
                />
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    // BDropdown, BDropdownItem, BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import Ripple from "vue-ripple-directive";
import moment from "moment";

export default {
    components: {
        VueApexCharts,
        // BDropdown,
        // BDropdownItem,
        BCard,
        // BButton,
        BRow,
        BCol,
    },
    directives: {
        Ripple,
    },
    props: {
        data: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            revenue_report: {},
            revenueReport: {
                chartOptions: {
                    chart: {
                        stacked: false,
                        toolbar: {
                            show: false,
                        },
                    },
                    responsive: [
                        {
                            breakpoint: 680,
                            options: {
                                legend: {
                                    position: "bottom",
                                },
                                xaxis: {
                                    labels: {
                                        show: false,
                                    },
                                },
                            },
                        },
                    ],
                    stroke: {
                        width: [0, 3],
                        curve: "smooth",
                        lineCap: "round",
                    },
                    grid: {
                        padding: {
                            top: -20,
                            bottom: -10,
                        },
                        yaxis: {
                            lines: {
                                show: false,
                            },
                        },
                    },
                    xaxis: {
                        categories: [],
                        labels: {
                            style: {
                                colors: "#6E6B7B",
                                fontSize: "0.86rem",
                                fontFamily: "Montserrat",
                            },
                        },
                        axisTicks: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                        },
                    },
                    legend: {
                        show: false,
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    colors: [$themeColors.primary, $themeColors.warning],
                    plotOptions: {
                        bar: {
                            columnWidth: "25%",
                            endingShape: "rounded",
                        },
                        distributed: true,
                    },
                    yaxis: [
                        {
                            labels: {
                                formatter: value =>
                                    `$${value.toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                    })}`,
                                style: {
                                    colors: "#6E6B7B",
                                    fontSize: "0.86rem",
                                    fontFamily: "Montserrat",
                                },
                            },
                        },
                        {
                            opposite: true,
                            labels: {
                                style: {
                                    colors: "#6E6B7B",
                                    fontSize: "0.86rem",
                                    fontFamily: "Montserrat",
                                },
                            },
                        },
                    ],
                },
            },
        };
    },
    created() {
        const date = new Date();
        for (let i = 0; i < 14; i += 1) {
            this.revenueReport.chartOptions.xaxis.categories.push(moment(date).format("MM/DD"));
            date.setDate(date.getDate() - 1);
        }
        this.revenueReport.chartOptions.xaxis.categories = this.revenueReport.chartOptions.xaxis.categories.reverse();
    },
};
</script>
