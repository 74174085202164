<template>
    <b-card no-body>
        <b-card-header>
            <h4 class="mb-0">Sales Overview</h4>
        </b-card-header>

        <!-- apex chart -->
        <vue-apex-charts
            type="radialBar"
            height="300"
            :options="goalOverviewRadialBar.chartOptions"
            :series="goalOverviewRadialBar.series"
        />
        <b-row class="text-center mx-0">
            <b-col cols="6" class="border-top border-right d-flex align-items-between flex-column py-1">
                <b-card-text class="text-muted mb-0"> Completed </b-card-text>
                <h3 class="font-weight-bolder mb-0">
                    {{ overviewData.ecommerceOverview.completed | formatCurrency }}
                </h3>
                <span class="font-small-2 text-muted">
                    Previous Period:
                    {{ overviewData.ecommerceOverview.previousPeriod.previousCompleted | formatCurrency }}
                </span>
            </b-col>

            <b-col cols="6" class="border-top d-flex align-items-between flex-column py-1">
                <b-card-text class="text-muted mb-0"> Total Orders </b-card-text>
                <h3 class="font-weight-bolder mb-0">
                    {{ overviewData.ecommerceOverview.sum | formatCurrency }}
                </h3>
                <span class="font-small-2 text-muted">
                    Previous Period: {{ overviewData.ecommerceOverview.previousPeriod.sum | formatCurrency }}
                </span>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import { BCard, BCardHeader, BRow, BCol, BCardText } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

export default {
    components: {
        VueApexCharts,
        BCard,
        BCardHeader,
        BRow,
        BCardText,
        BCol,
    },
    props: {
        data: {
            type: Object,
            default: () => {},
        },
        overviewData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            goal_overview: {},
            goalOverviewRadialBar: {
                series: [this.overviewData.ecommerceOverview.ratio],
                chartOptions: {
                    chart: {
                        sparkline: {
                            enabled: true,
                        },
                        dropShadow: {
                            enabled: true,
                            blur: 3,
                            left: 1,
                            top: 1,
                            opacity: 0.1,
                        },
                    },
                    colors: ["#51e5a8"],
                    plotOptions: {
                        radialBar: {
                            offsetY: -10,
                            startAngle: -150,
                            endAngle: 150,
                            hollow: {
                                size: "77%",
                            },
                            track: {
                                background: "#ebe9f1",
                                strokeWidth: "50%",
                            },
                            dataLabels: {
                                name: {
                                    show: false,
                                },
                                value: {
                                    color: "#5e5873",
                                    fontSize: "2.86rem",
                                    fontWeight: "600",
                                },
                            },
                        },
                    },
                    fill: {
                        type: "gradient",
                        gradient: {
                            shade: "dark",
                            type: "horizontal",
                            shadeIntensity: 0.5,
                            gradientToColors: [$themeColors.success],
                            inverseColors: true,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 100],
                        },
                    },
                    stroke: {
                        lineCap: "round",
                    },
                    grid: {
                        padding: {
                            bottom: 30,
                        },
                    },
                },
            },
        };
    },
};
</script>
