<template>
    <section id="dashboard-ecommerce">
        <b-alert variant="danger" :show="!isFetching && data === undefined || data === {}">
            <h4 class="alert-heading">Error getting e-commerce data. Check your internet connection and try again.</h4>
        </b-alert>
        <b-row v-if="!isFetching && data !== {}" class="match-height">
            <b-col lg="4">
                <ecommerce-overview :overview-data="overviewData" />
            </b-col>
            <b-col lg="8">
                <ecommerce-revenue-report :data="data.revenue" />
            </b-col>
        </b-row>

        <b-row v-if="!isFetching && data !== {}" class="match-height">
            <!-- Company Table Card -->
            <b-col lg="12">
                <ecommerce-company-table :table-data="data.companyTable" />
            </b-col>
            <!--/ Company Table Card -->
        </b-row>
        <b-col v-if="isFetching" class="mt-2">
            <div class="h-100 d-flex justify-content-center align-items-center">
                <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
            </div>
        </b-col>
    </section>
</template>

<script>
import { BRow, BCol, BSpinner, BAlert } from "bootstrap-vue";

import EcommerceRevenueReport from "./EcommerceRevenueReport.vue";
import EcommerceOverview from "./EcommerceOverview.vue";
import EcommerceCompanyTable from "./EcommerceCompanyTable.vue";

export default {
    components: {
        BRow,
        BCol,
        BSpinner,
        BAlert,
        EcommerceRevenueReport,
        EcommerceOverview,
        EcommerceCompanyTable,
    },
    data() {
        return {
            data: undefined,
            overviewData: {},
            isFetching: true,
        };
    },
    created() {
        // data
        this.$http.get("/analytics/ecommerce").then(response => {
            this.data = response.data;
            this.isFetching = false;
        });

        this.$http.get("/analytics/overview").then(response => {
            this.overviewData = response.data;
            this.isFetching = false;
        });
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
